import React from 'react';
import { MenuProps } from 'antd';
import { getPage } from "routers/router";
import { HomeOutlined, CameraOutlined,ToolOutlined ,SnippetsOutlined,FormOutlined, FundProjectionScreenOutlined, FundOutlined, CommentOutlined, BorderOuterOutlined} from '@ant-design/icons';


/**
 * 根据routeKey生成一个菜单链接
 * @param routeKey 定义在router.ts里面每个页面的key
 * @param title 在导航栏中显示的链接名称，可选，默认为页面在router.ts中定义的标题
 * @returns 
 */
 const itemOfPage = (routeKey:string, title?:string) => {
    const page = getPage(routeKey);
    const path = page.path;
    const label = title || page.title;

    return {label, key: routeKey, path}
}


/**
* 导航菜单设计
*/
const items: MenuProps['items'] = [
  {
      ...itemOfPage("index"), 
      icon: <HomeOutlined />
  },
  {
    ...itemOfPage("efmRecords"),
    icon:<SnippetsOutlined/>
  },
  {
    label: "标注与批注查询",
    icon : <FundOutlined />,
    key : "labelsAndComments",
    children: [
      {
        ...itemOfPage("labels"),
        icon:<BorderOuterOutlined/>
      },
      {
        ...itemOfPage("comments"),
        icon:<CommentOutlined/>
      }
    ]
  },
  {
    ...itemOfPage("caseRecords"),
    icon:<FormOutlined/>

  },
  {...itemOfPage("takePhotos"),
      icon:<CameraOutlined />

  },

  /*
  {
    label: "病例与标注",
    icon : <FundOutlined />,
    key : "tagging",
    children: [
      {
        ...itemOfPage("efmRecords"),
        icon:<SnippetsOutlined/>
      },
      {
        ...itemOfPage("caseRecords"),
        icon:<FormOutlined/>
      }
    ]
  },
  {
    label: "课程设计",
    icon : <FundProjectionScreenOutlined />,
    key: "courseManage",
    children: [
      itemOfPage("preEfmRecords"),
      itemOfPage("EfmSegments"),
      itemOfPage("Courses"),
      itemOfPage("examinationManage"),
      itemOfPage("studyPlans"),
    ]
  },
  {
    label: "教学实施",
    icon : <SnippetsOutlined />,
    key: "study",
    children: [
      itemOfPage("myStudyPlans"),
      itemOfPage("MyExaminationQuestions")
    ]
  },*/
  {
    label: "系统管理",
    icon: <ToolOutlined />,
    key: "system",
    children: [
      itemOfPage("users"),
      itemOfPage("roles"),
      itemOfPage("importLogger"),
      itemOfPage("popedoms"),
      itemOfPage("labelDefine")
    ]
  }
];

export default items;